import React, { useContext, useState, useEffect, useCallback } from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"

import { GlobalStateContext } from '../../utils/GlobalContextProvider';
import useWindowSize from '../../utils/useWindowSize';
import { ProductCard } from '../ProductCard/ProductCard';
import paysage2 from '../../../static/paysage2.jpg'
import logoVitaFons from '../../../static/logoVitaFons.jpg'
import StyledSectionAllProducts from './StyledSectionAllProducts';

export const SectionAllProducts = ({ products, data }) => {
    const { titlePage } = useContext(GlobalStateContext)
    const [nbColumn, setNbColumn] = useState(4)
    const sizeWindow = useWindowSize();

    useEffect(() => {
        if (window.innerWidth > 1384) {
            setNbColumn(4)
        } else if (window.innerWidth >= 1050) {
            setNbColumn(3)
        } else if (window.innerWidth >= 500) {
            setNbColumn(2)
        } else {
            setNbColumn(1)
        }
    }, [sizeWindow])

    const { productsData } = useStaticQuery(
        graphql`
            query {
                productsData:allDatoCmsProduct(sort: {fields: idOrder}) {
                    edges {
                      node {
                        idOrder
                        categorie
                        taxe
                        contenance
                        montantDeLaRemise
                        nombreDUnitDansCeLot
                        originalId
                        prix
                        prixDuLotAvecRemise
                        ceProduitExisteEnLot
                        sousTitre
                        titre
                        image {
                          fixed(height: 240) {
                            src
                            ...GatsbyDatoCmsFixed
                          }
                        }
                        descriptionNode {
                            childMarkdownRemark {
                              html
                              excerpt(pruneLength: 300)
                            }
                        }
                      }
                    }
                  }
            }
        `)

    const categories = [
        {
            title: 'HUMAINS ET ANIMAUX',
        },
        {
            title: 'PLANTES ET SOLS',
        },
        {
            title: 'LIVRES ET LETTRES',
        },
    ]

    const categorie1 = productsData?.edges?.filter(({ node }) => node.categorie.includes("1")) || []
    const categorie2 = productsData?.edges?.filter(({ node }) => node.categorie.includes("2")) || []
    const categorie3 = productsData?.edges?.filter(({ node }) => node.categorie.includes("3")) || []

    const resetWrongProducts = useCallback(async ({ state, products = [] }) => {
        try {
            await Promise.all(products.map(async product => {
                const item = state.cart.items.items.find(({ id }) => {
                    return id === product.originalId
                })

                if (!item) return null;
                if (item.price !== product.prix) {
                    return window.Snipcart.api.cart.items.remove(item.uniqueId);
                }
            }))
        } catch (err) {
            console.log(err)
        }
    }, [])

    useEffect(() => {
        if (window) {
            window.Snipcart.events.on('snipcart.initialized', (state) => {
                const products = productsData?.edges?.map(({ node }) => node)
                resetWrongProducts({ state, products })
            });
        }
    }, [resetWrongProducts, productsData])

    return (
        <StyledSectionAllProducts ref={products} >
            <h3>
                {nbColumn === 1 || nbColumn === 2 ?
                    <img alt='logo' src={logoVitaFons} />
                    : null
                }
                {titlePage}<small className='copyright'>® </small>
            </h3>
            <div className='container-cat one'>
                <div className='wrapper-desc-cat'>
                    <div className='wrapper-title'>
                        <h4>{categories[0].title}</h4>
                    </div>
                    <div className='wrapper-desc'>
                        <p>{data.descriptionCategorie1}</p>
                        <Link className='liens' to='/humainsetanimaux'>Comment les utiliser ?</Link>
                    </div>
                </div>
                {
                    categorie1.map((product, id) => {
                        if (id !== 2 && nbColumn === 4 || nbColumn === 2) {
                            return (
                                <ProductCard key={id} product={product.node} />
                            )
                        } else if (id !== 1 && nbColumn === 3 || nbColumn === 2) {
                            return (
                                <ProductCard key={id} product={product.node} />
                            )
                        } else {
                            return (
                                <>
                                    <div className='wrapper-logo'>
                                        <img alt='logo' src={logoVitaFons} />
                                    </div>
                                    <ProductCard product={product.node} />
                                </>
                            )
                        }
                    })
                }
            </div>
            <div className='container-cat two'>
                <div className='wrapper-desc-cat'>
                    <div className='wrapper-title'>
                        <h4>{categories[1].title}</h4>
                    </div>
                    <div className='wrapper-desc'>
                        <p>{data.descriptionCategorie2}</p>
                        <Link className='liens' to='/plantesetsols'>Comment les utiliser ?</Link>
                    </div>
                </div>
                {
                    categorie2.map((product, id) => {
                        return (
                            <ProductCard key={id} product={product.node} />
                        )
                    })
                }
                {nbColumn !== 2 && nbColumn !== 1 &&
                    <div className='wrapper-howuse'>
                        <div className='dangerousHTML' dangerouslySetInnerHTML={{ __html: data.cadreDeTexteN1Node.childMarkdownRemark.html }} />
                        <Link className='liens' to='/humainsetanimaux'>EN SAVOIR PLUS</Link>
                    </div>
                }
            </div>
            <div className='container-cat three'>
                <div className='wrapper-desc-cat'>
                    <div className='wrapper-title'>
                        <h4>{categories[2].title}</h4>
                    </div>
                    <div className='wrapper-desc'>
                        <p>{data.descriptionCategorie3}</p>
                        <a rel='noopener noreferrer' target='_blank' href={data.lienCategorie3EnSavoirPlus}>En savoir plus</a>
                    </div>
                </div>
                {
                    categorie3.map(product => {
                        return (
                            <ProductCard product={product.node} />
                        )
                    })
                }
                <div className='wrapper-logo'>
                    <img alt='logo' src={logoVitaFons} />
                </div>
            </div>
            {nbColumn === 2 || nbColumn === 1 ?
                <div className='wrapper-howuse'>
                    <div className='dangerousHTML' dangerouslySetInnerHTML={{ __html: data.cadreDeTexteN1Node.childMarkdownRemark.html }} />
                    <Link className='liens' to='/humainsetanimaux'>EN SAVOIR PLUS</Link>
                </div>
                : null
            }
            <div className='container-txt-vitafons'>
                <div className='wrapper-howuse'>
                    <div className='dangerousHTML' dangerouslySetInnerHTML={{ __html: data.cadreDeTexteN2Node.childMarkdownRemark.html }} />
                    <Link className='liens' to='/presentation-vitafonsii'>EN SAVOIR PLUS</Link>
                </div>
                <img alt='paysage2' src={paysage2} />
            </div>

            <div className='container-txt-vitafons two'>
                <div className='wrapper-howuse'>
                    <div className='dangerousHTML' dangerouslySetInnerHTML={{ __html: data.cadreDeTexteN3Node.childMarkdownRemark.html }} />
                    <Link className='liens' to='/servicedesoutien'>EN SAVOIR PLUS</Link>
                </div>
            </div>
        </StyledSectionAllProducts>
    )
}
