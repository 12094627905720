import styled from 'styled-components'

const StyledBannerBackground = styled.div`
    width: 1384px;
    height: 504px;
    ${'' /* background: url(https://i.imgur.com/Sc4SGP0.jpg); */}
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    position: relative;

        @media screen and (max-width: 1384px) {
            width: 100%;
                img {
                    width: 100%;
                    position: absolute;
                    z-index: -1;
                    bottom: 0;
                }
        } 
        @media screen and (max-width: 1250px) {
            width: 100%;
                img {
                    height: 100%;
                    width: auto !important;
                    position: absolute;
                    z-index: -1;
                    bottom: 0;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
        } 
        @media screen and (max-width: 1000px) {
            width: 100%;
            align-items: center !important;
                .container-bannerbg {
                    padding: 0 !important;
                }
        } 

        @media screen and (max-width: 500px) {
            height: 72px;
            
            img, .container-bannerbg {
                display: none !important;
            }
        } 

        img {
            width: 100%;
            position: absolute;
            z-index: -1;
        }

        .container-bannerbg {
            display: flex;
            flex-direction: column;
            height: auto;
            align-items: center;
            justify-content: center;
            padding-right: 87px;
            max-width: 600px;
        }

        h1 {
            font-family: Trykker;
            font-style: normal;
            font-weight: normal;
            font-size: 48px;
            line-height: 60px;
            display: flex;
            align-items: center;
            text-align: center;
            color: black;
            text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        }

        h2 {
            font-family: Raleway;
            font-style: normal;
            font-weight: 600;
            font-size: 48px;
            line-height: 56px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #FFFFFF;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            padding: 50px 0;
            max-width: 
        }

        button {
            background: linear-gradient(180deg, #8F6A8F 0%, #4E1D4E 100%);
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
            cursor: pointer;
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 29px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #FFFFFF;
            width: 200px;
            height: 49px;
            transition: 500ms;
        }

        button:hover {
            transform: scale(1.1);
            transition: 500ms;
        }
`

export default StyledBannerBackground