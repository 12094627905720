import styled from 'styled-components'

const StyledPageHome = styled.section`
    width: 1384px;
    
        @media screen and (max-width: 1384px) {
            width: 100%;
        } 
`


export default StyledPageHome