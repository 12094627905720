import React from 'react'

import StyledBannerBackground from './StyledBannerBackground';

export const BannerBackground = ({ products, data }) => {

    const scrollToRef = (ref) => window.scrollTo({
        top: ref.current.offsetTop,
        behavior: 'smooth'
      })

    return (
        <StyledBannerBackground>
            <section className='container-bannerbg'>
                <h1>VITA FONS II</h1>
                <h2>{data.phraseDAccueil}</h2>
                <button onClick={() => scrollToRef(products)}>Découvrir</button>
            </section>
            <img alt='bannière' src={data.imageBanniere.fixed.src}/>
        </StyledBannerBackground>
    )
}
