import React, { useRef } from 'react'
import { useStaticQuery, graphql } from "gatsby"

import StyledPageHome from './StyledPageHome';
import { BannerBackground } from '../BannerBackground/BannerBackground';
import { SectionAllProducts } from '../SectionAllProducts/SectionAllProducts';

export const PageHome = () => {
    const data = useStaticQuery(
        graphql`
            query {
                datoCmsPageAccueil {
                    phraseDAccueil
                    imageBanniere {
                        fixed(width: 1384) {
                          src
                        }
                    }
                    descriptionCategorie1
                    descriptionCategorie2
                    descriptionCategorie3
                    lienCategorie3EnSavoirPlus
                    cadreDeTexteN1Node {
                        childMarkdownRemark {
                          html
                        }
                      }
                      cadreDeTexteN2Node {
                        childMarkdownRemark {
                          html
                        }
                      }
                      cadreDeTexteN3Node {
                        childMarkdownRemark {
                          html
                        }
                      }
                }
            }
          `)

    let products = useRef()

    return (
        <StyledPageHome>
            <BannerBackground products={products} data={data.datoCmsPageAccueil} />
            <SectionAllProducts products={products} data={data.datoCmsPageAccueil} />
        </StyledPageHome>
    )
}
