import styled from 'styled-components'

const StyledSectionAllProducts = styled.section`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;

        @media screen and (max-width: 1384px) {
            justify-content: center;
            .container-cat {
                padding: 0 24px;
                grid-template-columns: repeat(3, 328px) !important;
            }

            .container-cat.two {
                grid-template-areas: "a b c"
                                     "d e f" 
                                     "g g g" !important;
            }
            .wrapper-howuse {
                grid-area: g !important;
            }

            .container-cat.three .wrapper-logo {
                display: none;
            }
            .container-txt-vitafons {
                width: 90% !important;
            }
        }

        @media screen and (max-width: 1050px) {
            .container-cat {
                padding: 0 24px;
                grid-template-columns: repeat(2, 328px) !important;
            }
            .container-cat.two {
                grid-template-areas: none !important;
                grid-template-columns: repeat(2, 328px) !important;
            }

            .wrapper-logo {
                display: none !important;
            }

            .container-cat .wrapper-desc-cat {
                width: 100% !important;
                margin: 0 !important;
                grid-column: 1 / span 2;
                grid-row-start: 1;
                grid-row: firstLine / 1;
            }
            .container-cat .wrapper-desc-cat .wrapper-desc {
                width: 100% !important;
            }
            .wrapper-howuse {
                width: 90%;
                margin-bottom: 72px;
            }
        }
        @media screen and (max-width: 930px) {
            .container-txt-vitafons {
                flex-direction: column-reverse;
            }
            .container-txt-vitafons img {
                width: 60% !important;
            }
            .container-txt-vitafons .wrapper-howuse {
                width: 100% !important;
            }
        }
        @media screen and (max-width: 700px) {
            .container-cat {
                padding: 0 24px;
                display: flex !important;
                flex-direction: column !important;
                justify-content: center;
                align-items: center;
                width: 97%;
            }
        }

        h3 {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            line-height: 29px;
            text-align: center;
            color: #000000;
            padding: 24px 0 48px 0;
            position: relative;
            display: flex;
            align-items: center;
        }
        h3 img {
            height: 40px;
            right: -35px;
            top: 18px;
            position: absolute;
        }

        .container-cat {
            height: auto;
            display: grid;
            grid-gap: 48px 24px;
            margin-bottom: 96px;
            grid-template-columns: repeat(4, 328px);
            grid-template-rows: auto;
        }
        ${'' /* .container-cat.one, .container-cat.three {
            ${'' /* grid-template-columns: repeat(auto-fill, 328px);
            grid-template-rows: auto; */}
        } */}
        .container-cat.two {
            grid-template-areas: "a b c d"
                                 "e f f f";
        }

        .container-cat .wrapper-desc-cat {
            height: calc(142px + 170px);
            width: 280px;
            display: flex;
            flex-direction: column;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
            margin: 24px 48px 24px 24px;
        }

        .container-cat .wrapper-logo {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content:center;
        }
        .container-cat .wrapper-logo img {
            height: 312px;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
        }
        .container-cat .wrapper-desc-cat .wrapper-title {
            background: linear-gradient(180deg, #8F6A8F 0%, #4E1D4E 100%);
            height: 142px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .container-cat .wrapper-desc-cat .wrapper-title h4 {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            line-height: 34px;
            text-align: center;
            color: #FFFFFF;
        }
        .container-cat .wrapper-desc-cat .wrapper-desc {
            width: 280px;
            height: 170px;
            background: #F4E1F4;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 8px 16px;
        }
        .container-cat .wrapper-desc-cat .wrapper-desc p {
            font-family: Lato;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #000000;
            padding-bottom: 24px;
        }
        .container-cat .wrapper-desc-cat .wrapper-desc a {
            font-family: Lato;
            font-style: normal;
            font-weight: 900;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: black;
            text-decoration: none;
            transition: 400ms;
        }
        .container-cat .wrapper-desc-cat .wrapper-desc a:hover {
            transform: scale(1.05);
            transition: 400ms;
        }

        .wrapper-howuse {
            grid-area: f;
            background: #F4E1F4;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
            position: relative;
            padding: 24px;
        }
        .wrapper-howuse h5 {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 27px;
            padding-bottom: 24px;
        }
        .wrapper-howuse p {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 19px;
        }

        .wrapper-howuse a.liens {
            position: absolute;
            right: 112px;
            bottom: -20px;
            width: 141.65px;
            height: 40px;
            background: #FFFFFF;
            text-decoration: none;
            color: black;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            transition: 400ms;
        }

        .wrapper-howuse a.liens:hover {
            transform: scale(1.03);
            box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
            transition: 400ms;
        }
        .wrapper-howuse a {
            font-weight: bold;
            color: black;
            opacity: 0.9;
            text-decoration: none;
        }
        .wrapper-howuse a:hover {
            font-weight: bold;
            color: black;
            opacity: 1;
            text-decoration: none;
        }

        .container-txt-vitafons {
            width: 1256px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .container-txt-vitafons.two {
            margin-top: 96px;
        }
        .container-txt-vitafons.two .wrapper-howuse p {
            font-size: 18px;
            line-height: 24px;
        }
        .container-txt-vitafons .wrapper-howuse {
           width: 65%;
        }
        .container-txt-vitafons .wrapper-howuse h5 {
           font-size: 25px;
        }
        .container-txt-vitafons .wrapper-howuse p {
           font-size: 16px;
        }
        .container-txt-vitafons img {
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
            width: 35%;
        }

`

export default StyledSectionAllProducts